import AppWidget_ClassicComponent from '@wix/thunderbolt-elements/src/components/AppWidget/viewer/skinComps/Classic/Classic.skin';


const AppWidget_Classic = {
  component: AppWidget_ClassicComponent
};


export const components = {
  ['AppWidget_Classic']: AppWidget_Classic
};


// temporary export
export const version = "1.0.0"
